import request from "../helpers/request";

//Obtener los brokers externos..
export function getExternalBroker() {
  try {
    const data = {};
    return new Promise((resolve, reject) => {
      request("/ContactBroker/obtenerInfoPlancharBrokersExternos", data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

export function plancharBrokexExternos(data) {
  try {
    return new Promise((resolve, reject) => {
      request("/ContactBroker/PlancharBrokersExternos", data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
