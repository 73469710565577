<template>
  <div>
    <h1>Merge leads con broker externo..</h1>
    <p>Ingresar nombre, telefono y correo del broker externo.</p>
    <p>El nombre de la inmobiliaria se puede omitir.</p>
    <p>
      Si la oportunidad ya esta vinculada a un broker interno se conservara con
      el mismo, si no, buscara por nombre del broker interno y se asignara a la
      oportunidad en caso de que se encuentre.
    </p>

    <!-- Ejemplo de card ... ir a https://vuetifyjs.com/en/ para mas información-->
    <v-data-table
      :headers="headers"
      :items="data"
      :items-per-page="1"
      class="elevation-1"
    >
      <template v-slot:item.scrapedData="{ item }">
        B.I:
        <v-text-field
          label="no encontrado"
          solo
          v-model="item.scrapedData.internalBroker"
        ></v-text-field>

        BrEx:
        <v-text-field
          label="no encontrado"
          solo
          v-model="item.scrapedData.externalBroker"
        ></v-text-field>

        Inmo:
        <v-text-field
          label="no encontrado"
          solo
          v-model="item.scrapedData.real_estate_name"
        ></v-text-field>

        Tel:
        <v-text-field
          label="no encontrado"
          solo
          v-model="item.scrapedData.phone"
        ></v-text-field>

        Mail:
        <v-text-field
          label="no encontrado"
          solo
          v-model="item.scrapedData.email"
        ></v-text-field>
        ═FIN BROKER═
      </template>
      <template v-slot:item.btn="{ item }">
        <v-btn @click="getRow(item)">Ejecutar</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
//Ciclo de vida vue --> https://codingpotions.com/vue-ciclo-vida
import {
  getExternalBroker,
  plancharBrokexExternos
} from "@/api/merge-external-broker-api.js";
export default {
  name: "MergeExternalBroker",
  data() {
    return {
      //variables
      data: [],
      headers: [
        {
          text: "ID de oportunidad",
          value: "_id"
        },
        { text: "Lead", value: "contact_lead_name", width: "20%" },
        { text: "zones", value: "zones" },
        { text: "Observations original", value: "observations", width: "20%" },
        { text: "data scrapeada", value: "scrapedData" },
        { text: "ejecutar?", value: "btn" }
      ]
    };
  },
  created() {
    //Se ejecuta cuando se crea el componente
    this.init();
  },

  methods: {
    //Metodos
    init() {
      getExternalBroker()
        .then(response => {
          if (response.data.Code !== 200) {
            throw new Error(response.data.Message);
          }
          this.data = response.data.externalBrokerData;
          //Descomenta y abre la consola para ver la respuesta..
        })
        .catch(error => {
          this.$snotify.error(error, {
            timeout: 3000,
            showProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true
          });
        })
        .finally();
    },
    getRow(item) {
      if (
        item.scrapedData.phone === "" ||
        item.scrapedData.email === "" ||
        item.scrapedData.externalBroker === ""
      ) {
        this.$snotify.warning(
          "Solo se puede dejar vacio la inmobiliaria o el broker interno, se requiere nombre,telefono y correo de broker externo",
          {
            timeout: 3000,
            showProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true
          }
        );
        return;
      }
      plancharBrokexExternos(item)
        .then(response => {
          if (response.data.Code !== 200) {
            throw new Error(response.data.Message);
          }

          return getExternalBroker();
        })
        .then(response => {
          this.data = response.data.externalBrokerData;
          if (response.data.Code !== 200) {
            throw new Error(response.data.Message);
          }
          this.$snotify.success(
            `Oportunidad ${item._id} planchada con broker externo`,
            {
              timeout: 3000,
              showProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true
            }
          );
        })
        .catch(error => {
          this.$snotify.error(error, {
            timeout: 3000,
            showProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true
          });
        })
        .finally();
    }
  }
};
</script>
